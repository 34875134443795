import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import SEO from 'components/seo';
import MediaLayout from 'layouts/media-layout';
import { Col33, ArrowToBottom, GoBackButton, MobileHeading } from 'components/misc';
import { media } from 'utils';

const PHOTOS_VIDEOS = [
  {
    id: 1,
    title: 'Nowa siedziba w Kisielinie',
    date: '09.03.2020',
    name: 'media-headquarters'

  },
  {
    id: 2,
    title: 'Odwiedziny Prezydenta',
    date: '09.03.2020',
    name: 'media-car'
  },
  {
    id: 3,
    title: 'Dostawa Hamburg VHH',
    date: '09.03.2020',
    name: 'media-shipyard'
  },
  {
    id: 4,
    title: 'Nowa siedziba w Kisielinie',
    date: '09.03.2020',
    name: 'media-headquarters-copy'
  },
  {
    id: 5,
    title: 'Odwiedziny Prezydenta',
    date: '09.03.2020',
    name: 'media-car-copy'
  },
  {
    id: 6,
    title: 'Dostawa Hamburg VHH',
    date: '09.03.2020',
    name: 'media-shipyard-copy'
  },
];

const MobileElementsWrapper = styled.div`
  display: ${({ isVisible })=> isVisible ? 'block' : 'none'};
`;

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.tablet`
    display: ${({ isVisible })=> !isVisible && 'none'};
  `}
`;

const StyledCol33 = styled(Col33)`
  margin-bottom: 30px;
`;

const PhotoVideoHeading = styled.div``;

const Title = styled.h3`
  font-size: 1.285rem;
  font-weight: 500;
`;

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
  margin: 8px 0;
`;

const FotoVideo = (()=> {
  const { mediaImages: { nodes }} = useStaticQuery(graphql`
  {
    mediaImages: allFile(filter: {name: {regex: "/^media-/"}}) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
    }
  }`);

  const [isMediaRoot, setIsMediaRoot] = useState(true);

  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setIsMediaRoot(location.pathname === '/media' || location.pathname === '/media/');
  }, []);

  const getImage = (imageName)=> {
    const image = nodes.find(({ name })=> name === imageName);

    if (image) {
      return <PhotoVideoHeading>
        <StyledImage fluid={image.childImageSharp.fluid} alt={image.name} />
      </PhotoVideoHeading>;
    }
  };

  const handleArrowClick = (id)=> ()=> {
    console.log('was clicked', id);
  };

  return (
    <MediaLayout>
      <SEO title="Media" />
      <MobileElementsWrapper isVisible={!isMediaRoot}>
        <GoBackButton path="media" name="Media" />
        <MobileHeading heading="Foto Video" />
      </MobileElementsWrapper>
      <Gallery isVisible={!isMediaRoot}>
        {PHOTOS_VIDEOS.map((photoVideo)=> {
          return <StyledCol33 key={photoVideo.name}>
            <PhotoVideoHeading>
              <p>{photoVideo.date}</p>
              <Title>{photoVideo.title}</Title>
              {getImage(photoVideo.name)}
            </PhotoVideoHeading>
            <ArrowToBottom onClick={handleArrowClick(photoVideo.id)} title="Pobierz press pack" />
          </StyledCol33>;
        })}
      </Gallery>
    </MediaLayout>
  );
});

export default FotoVideo;
