import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Section, Container, Aside, Col75 } from 'components/misc';
import { Link } from 'gatsby';
import SEO from 'components/seo';
import { media } from 'utils';
import activeColor from '../style/active-color';

const StyledAside = styled(Aside)`
  > div {
    opacity: 1;
  }

  ${media.tablet`
    display: ${({ isVisible })=> isVisible ? 'block' : 'none'};
  `}
`;

const FlexContainer = styled(Container)`
  display: flex;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const StyledLink = styled(Link)`
  font-size: 2.5714rem;
  font-weight: 700;
  color: ${({ theme })=> theme.color.gray};
`;

const MediaLayout = ({ children })=> {
  const [isMediaRoot, setIsMediaRoot] = useState(true);

  useEffect(()=> {
    setIsMediaRoot(location.pathname === '/media' || location.pathname === '/media/');
  }, []);

  const isActive = ({ isCurrent, location })=> {
    return isCurrent || location.pathname === '/media' || location.pathname === '/media/' ? { style: activeColor } : {};
  };

  const ExtendedLink = (props)=> (
    <StyledLink getProps={isActive} {...props} />
  );

  return (
    <>
      <SEO title="Media" />
      <Section topSection>
        <FlexContainer>
          <StyledAside isVisible={isMediaRoot}>
            <ul>
              <li><ExtendedLink to="/media/foto-video">Foto Video</ExtendedLink></li>
              <li><StyledLink activeStyle={activeColor} to="/media/press-info">Informacje prasowe</StyledLink></li>
              <li><StyledLink activeStyle={activeColor} to="/media/presentations">Prezentacje</StyledLink></li>
              <li><StyledLink activeStyle={activeColor} to="/media/awards">Nagrody</StyledLink></li>
              <li><StyledLink activeStyle={activeColor} to="/media/book-of-mark">Księga znaku</StyledLink></li>
            </ul>
          </StyledAside>
          <Col75>
            {children}
          </Col75>
        </FlexContainer>
      </Section>
    </>
  );
};

export default MediaLayout;
